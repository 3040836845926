import Image from "next/image";
import Link from "next/link";
import logo from "/public/img/logo.svg";
import { useAuth } from "../../contexts/AuthUserContext";
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { t } from "i18next";
import { signIn as signInAPI, signUp as signUpAPI } from "../../apis/sign";
import { signIn as signInAuth } from 'next-auth/react';
import { useRouter } from "next/router";
import { v4 } from "uuid";

export default function LoginForm() {
    const router = useRouter();

    const {
        getBearerToken,
        signInWithEmailAndPassword,
        googleAuthSignInWithPopup,
        facebookAuthSignInWithPopup,
        appleAuthSignInWithPopup,
        signOut,
    } = useAuth();

    const onSubmitLogin = (e) => {
        e.preventDefault();

        const email = e.target.email.value;
        const password = e.target.password.value;

        signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                
                if (user.emailVerified === false) {
                    signOut();
                    alert('해당 이메일 주소에 보내진 이메일 인증을 완료해주세요.');
                    return;
                }

                signIn(user, 'EMAIL')
                    .catch((error) => alert('오류가 발생했습니다.'));
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                
                switch (error.code) {
                    case 'auth/wrong-password':
                    case 'auth/user-not-found':
                        alert('이메일 또는 비밀번호를 잘못 입력했습니다');
                        break;
                        
                    default:
                        alert('오류가 발생했습니다.');
                        break;
                }
            });
    };

    const onAuthGoogle = (e) => {
        googleAuthSignInWithPopup()
            .then((result) => {
                const user = result.user;
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;

                signIn(user, 'GOOGLE')
                    .catch((error) => {
                        if (error.code === 3000) {
                            signUp(user, 'GOOGLE').then(() => signIn(user, 'GOOGLE'));
                            return;
                        }

                        alert('오류가 발생했습니다.');
                    });
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                const credential = GoogleAuthProvider.credentialFromError(error);

                switch (error.code) {
                    case 'auth/cancelled-popup-request':
                    case 'auth/popup-closed-by-user':
                        break;
                    
                    default:
                        alert('오류가 발생했습니다.');
                        break;
                }
            });
    };

    const onAuthFacebook = (e) => {
        facebookAuthSignInWithPopup()
            .then((result) => {
                const user = result.user;
                const credential = FacebookAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;

                signIn(user, 'FACEBOOK')
                    .catch((error) => {
                        if (error.code === 3000) {
                            signUp(user, 'FACEBOOK').then(() => signIn(user, 'FACEBOOK'));
                            return;
                        }
                        
                        alert('오류가 발생했습니다.');
                    });
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                const credential = FacebookAuthProvider.credentialFromError(error);
                
                switch (error.code) {
                    case 'auth/cancelled-popup-request':
                    case 'auth/popup-closed-by-user':
                        break;
                    
                    default:
                        alert('오류가 발생했습니다.');
                        break;
                }
            });
    };

    const onAuthApple = (e) => {
        appleAuthSignInWithPopup()
            .then((result) => {
                const user = result.user;
                const credential = OAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;
                const idToken = credential.idToken;
                
                signIn(user, 'APPLE')
                    .catch((error) => {
                        if (error.code === 3000) {
                            signUp(user, 'APPLE').then(() => signIn(user, 'APPLE'));
                            return;
                        }
                        
                        alert('오류가 발생했습니다.');
                    });
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                const credential = OAuthProvider.credentialFromError(error);
                
                switch (error.code) {
                    case 'auth/cancelled-popup-request':
                    case 'auth/popup-closed-by-user':
                        break;
                    
                    default:
                        alert('오류가 발생했습니다.');
                        break;
                }
            });
    };

    const onAuthNaver = (e) => {
        const state = encodeURI(v4());
        
        const authNaverUrl = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.NEXT_PUBLIC_NAVER_CLIENT_ID}&redirect_uri=${encodeURI(`${process.env.NEXT_PUBLIC_NAVER_CALLBACK_URL}`)}&state=${state}`;
        router.push(authNaverUrl);
    }

    const onAuthKakao = (e) => {
        const state = encodeURI(v4());

        const authKakaoUrl = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${process.env.NEXT_PUBLIC_KAKAO_REST_API_KET}&redirect_uri=${encodeURI(`${process.env.NEXT_PUBLIC_KAKAO_CALLBACK_URL}`)}&state=${state}`;
        router.push(authKakaoUrl);
    }

    const signIn = async (user, type) => {
        const data = {
            email: user.email,
            loginType: type,
            deviceOs: 'WEB',
        };
    
        const headers = {
            Authorization: getBearerToken(),
        };
    
        const res = await signInAPI(data, { headers });
        
        if (res.data.status != 0) {
            throw { code: res.data.status, message: res.data.message };
        }
        
        const moreInfo = res.data.data;
        
        signInAuth('credentials', {
            uid: user.uid,
            email: user.email,
            memberId: moreInfo.id,
            bearerToken: getBearerToken(),
            provider: type,
            redirect: false
        });
    }
    
    const signUp = async (user, type) => {
        const data = {
            email: user.email,
            userAgreed: true,
            privacyAgreed: true,
            marketingAgreed: false,
            provider: type,
        };
    
        const headers = {
            Authorization: getBearerToken(),
        };
    
        const res = await signUpAPI(data, { headers });
        
        if (res.data.status != 0) {
            throw { code: res.data.status, message: res.data.message };
        }
    }
    
    return (
        <>    
            <form className="section border-4 background-white box-shadow-1 px-3 px-md-4 py-5" onSubmit={onSubmitLogin}>
                <div className="row justify-content-center">
                    <div className="col-auto pb-4 mb-2">
                        <Link href="/">
                            <div className="logo-login">	
                                <Image src={logo} alt="" />
                            </div>	
                        </Link>
                    </div>
                    <div className="col-12 text-center">
                        <h6 className="mb-3">{t('logIn.title')}</h6>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <input type="email" name="email" className="form-style" placeholder={t('logIn.emailPlaceholder')} autoComplete="off" required />
                        </div>	
                    </div>
                    <div className="col-12 mt-2">
                        <div className="form-group">
                            <input type="password" name="password" className="form-style form-bid" placeholder={t('logIn.passwordPlaceholder')} autoComplete="off" required />
                        </div>	
                    </div>
                    <div className="col-12 my-3 text-center">
                        <p className="mb-0 size-13"><Link href="/forgotpass" className="link-normal weight-600">{t('logIn.forgotPasswordLabel')}</Link></p>
                    </div>
                    <div className="col-12 text-center">
                        <button type="submit" className="btn btn-blue parallax-elements js-tilt">{t('logIn.logInLabel')}<i className="uil uil-sign-in-alt size-24 ml-2"></i></button>
                    </div>
                    <div className="col-12 mt-3 mb-2 text-center">
                        <p className="mb-0 size-13">{t('logIn.paragraph1')}</p>
                    </div>
                    <div className="col-12 text-center">
                        <button onClick={onAuthGoogle} className="btn btn-small btn-google parallax-elements js-tilt mb-1 mr-1"><i className="uil uil-google size-20 "></i></button>
                        <button onClick={onAuthFacebook} className="btn btn-small btn-facebook parallax-elements js-tilt mb-1 mr-1"><i className="uil uil-facebook-f size-20"></i></button>
                        <button onClick={onAuthNaver} className="btn btn-small btn-naver parallax-elements js-tilt mb-1 mr-1"><i className="xi xi-naver size-20"></i></button>
                        <button onClick={onAuthKakao} className="btn btn-small btn-kakao parallax-elements js-tilt mb-1"><i className="xi xi-kakaotalk size-20"></i></button>
                    </div>
                    <div className="col-12 mt-3 text-center">
                        <p className="mb-0 size-14">{t('logIn.paragraph2')}<br />
                        <Link href="/signup" className="link-normal weight-600">{t('logIn.signUpLabel')}</Link> {t('logIn.paragraph3')}</p>
                    </div>
                </div>
            </form>
        </>
    );
}
