import { useRouter } from 'next/router';
import Base from '../components/layouts/base';
import Page from '../components/login/page';
import { useEffect } from 'react';
import { useSession } from 'next-auth/react';

export default function Login() {
    const router = useRouter();
    const { data: session } = useSession();
    
    useEffect(() => {
        if (session?.user)
          router.replace('/')
    }, [session, router]);

    return (
        <Base>
            <Page />
        </Base>
    );
}
