import Image from "next/image";
import logo from "/public/img/logo.svg";
import LoginForm from "./login-form";

export default function Page() {
    return (
        <div className="section full-height over-hide">
            <div className="back-logo-big-hero img-wrap d-none">
                <Image src={logo} alt="" />
            </div>
            <div className="section full-height z-bigger-20">
                <div className="container">
                    <div className="row justify-content-center full-height">
                        <div className="col-md-9 col-lg-7 col-xl-6 align-self-center pt-4 pb-5 nav-load-anime">
                            <LoginForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
