import axios from "axios";

const signIn = async (data, config) => {
    const dataToSend = {
        email: data.email,
        loginType: data.loginType,
        deviceOs: 'WEB',
    };

    const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/account/signin`, dataToSend, config);
    
    return res;
}

const signUp = async (data, config) => {
    const dataToSend = {
        email: data.email,
        userAgreed: data?.userAgreed ?? false,
        privacyAgreed: data?.privacyAgreed ?? false,
        marketingAgreed: data?.marketingAgreed ?? false,
        provider: data.provider,
    };

    const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/account/signup`, dataToSend, config);

    return res;
}

export { signIn, signUp };
